@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --border: 214 32% 91%;
    --input: 240 6% 90%;
    --ring: 240 6% 10%;
    --radius: 0.5rem;
    --container-padding: 1.25rem;

    /* new figma root variables */
    --colors-accent-dark: 226 57% 21%;
    --colors-accent-foreground-dark: 210 40% 98;
    --colors-accent-foreground-light: 224 64% 33%;
    --colors-accent-light: 214 100% 97%;
    --colors-background-dark: 229 84% 5%;
    --colors-background-light: 0 0% 100;
    --colors-border-dark: 217 33% 17%;
    --colors-border-light: 214 32% 91;
    --colors-button-accent-dark: 217 33% 17%;
    --colors-button-accent-light: 210 40% 98;
    --colors-card-dark: 229 84% 5%;
    --colors-card-foreground-dark: 210 40% 98;
    --colors-card-foreground-light: 229 84% 5;
    --colors-card-light: 0 0% 100;
    --colors-chart-1-dark: #2662d9;
    --colors-chart-1-light: #2a9d90;
    --colors-chart-2-dark: #e23670;
    --colors-chart-2-light: #e76e50;
    --colors-chart-3-dark: #e88c30;
    --colors-chart-3-light: #274754;
    --colors-chart-4-dark: #af57db;
    --colors-chart-4-light: #e8c468;
    --colors-chart-5-dark: #2eb88a;
    --colors-chart-5-light: #f4a462;
    --colors-destructive-dark: 79 71% 40;
    --colors-destructive-foreground-dark: 0 86% 97;
    --colors-destructive-foreground-light: 0 86% 97;
    --colors-destructive-light: 0 72% 51;
    --colors-foreground-dark: 210 40% 98;
    --colors-foreground-light: 222 47% 11;
    --colors-input-dark: 217 33% 17;
    --colors-input-light: 214 32% 91;
    --colors-muted-dark: 229 84% 5%;
    --colors-muted-foreground-dark: 214 32% 91%;
    --colors-muted-foreground-light: 215 25% 27;
    --colors-muted-light: 210 40% 98;
    --colors-open-bg-dark: 229 84% 5%;
    --colors-open-bg-light: 214 100% 97%;
    --colors-open-foreground-dark: 217 91% 60%;
    --colors-open-foreground-light: 221 83% 53;
    --colors-popover-dark: 229 84% 5%;
    --colors-popover-foreground-dark: 210 40% 98;
    --colors-popover-foreground-light: 229 84% 5%;
    --colors-popover-light: 0 0% 100;
    --colors-positive-dark: 142 69% 58;
    --colors-positive-light: 142 76% 36;
    --colors-primary-dark: 214 100% 97;
    --colors-primary-foreground-dark: 226 71% 40;
    --colors-primary-foreground-light: 214 100% 97%;
    --colors-primary-light: 221 83% 53%;
    --colors-reply-bg-dark: 145 80% 10;
    --colors-reply-bg-light: 138 76% 97;
    --colors-reply-foreground-dark: 142 71% 45;
    --colors-reply-foreground-light: 142 76% 36;
    --colors-ring-dark: 213 27% 84;
    --colors-ring-light: 222 47% 11;
    --colors-secondary-dark: 222 47% 11;
    --colors-secondary-foreground-dark: 210 40% 98;
    --colors-secondary-foreground-light: 222 47% 11;
    --colors-secondary-light: 210 40% 96;
    --colors-sent-bg-dark: 21 92% 14;
    --colors-sent-bg-light: 48 100% 96;
    --colors-sent-foreground-dark: 38 92% 50;
    --colors-sent-foreground-light: 32 95% 44;
    --colors-sidebar-accent-dark: 217 33% 37;
    --colors-sidebar-accent-foreground-dark: 210 40% 96;
    --colors-sidebar-accent-foreground-light: 222 47% 11;
    --colors-sidebar-accent-light: 210 40% 96;
    --colors-sidebar-background-dark: 222 47% 11;
    --colors-sidebar-background-light: 210 40% 98;
    --colors-sidebar-border-dark: 217 33% 17;
    --colors-sidebar-border-light: 214 32% 91%;
    --colors-sidebar-foreground-dark: 210 40% 98;
    --colors-sidebar-foreground-light: 215 25% 27;
    --colors-sidebar-muted-foreground-dark: 210 40% 98;
    --colors-sidebar-muted-foreground-light: 215 19% 35;
    --colors-sidebar-primary-dark: 217 91% 60%;
    --colors-sidebar-primary-foreground-dark: 0 0% 100;
    --colors-sidebar-primary-foreground-light: 210 40% 98;
    --colors-sidebar-primary-light: 222 47% 11;
    --colors-sidebar-ring-dark: 213 27% 84;
    --colors-sidebar-ring-light: 215 20% 65%;
    --colors-sidebar-sub-foreground-dark: 214 32% 91%;
    --colors-sidebar-sub-foreground-light: 215 16% 47;
    --colors-sub-foreground-dark: 215 20% 65;
    --colors-sub-foreground-light: 215 16% 47;
    /* number */
    --border-radius-default: var(--radius-rounded-sm);
    --border-radius-sm: var(--radius-rounded-sm);
    --border-radius-md: var(--radius-rounded-md);
    --border-radius-lg: var(--radius-rounded-lg);
    --border-radius-xl: var(--radius-rounded-xl);
    --border-radius-full: var(--radius-rounded-full);
    --typography-base-sizes-2x-large-font-size: var(--font-size-text-2xl);
    --typography-base-sizes-2x-large-line-height: var(--font-line-height-leading-8);
    --typography-base-sizes-3x-large-font-size: var(--font-size-text-3xl);
    --typography-base-sizes-3x-large-line-height: var(--font-line-height-leading-9);
    --typography-base-sizes-4x-large-font-size: var(--font-size-text-4xl);
    --typography-base-sizes-4x-large-line-height: var(--font-line-height-leading-10);
    --typography-base-sizes-base-font-size: var(--font-size-text-base);
    --typography-base-sizes-base-line-height: var(--font-line-height-leading-6);
    --typography-base-sizes-extra-small-font-size: var(--font-size-text-xs);
    --typography-base-sizes-extra-small-line-height: var(--font-line-height-leading-4);
    --typography-base-sizes-large-font-size: var(--font-size-text-lg);
    --typography-base-sizes-large-line-height: var(--font-line-height-leading-7);
    --typography-base-sizes-small-font-size: var(--font-size-text-sm);
    --typography-base-sizes-small-line-height: var(--font-line-height-leading-5);
    --typography-base-sizes-xlarge-font-size: var(--font-size-text-xl);
    --typography-base-sizes-xlarge-line-height: var(--font-line-height-leading-7);
    --typography-typography-components-blockquote-letter-spacing: var(--font-letter-spacing-normal);
    --typography-typography-components-blockquote-font-size: var(--font-size-text-base);
    --typography-typography-components-blockquote-line-height: var(--font-line-height-leading-6);
    --typography-typography-components-blockquote-font-weight: var(--font-weight-normal);
    --typography-typography-components-h1-letter-spacing: var(--font-letter-spacing-tight);
    --typography-typography-components-h1-font-size: var(--font-size-text-4xl);
    --typography-typography-components-h1-line-height: var(--font-line-height-leading-10);
    --typography-typography-components-h1-font-size-lg: var(--font-size-text-5xl);
    --typography-typography-components-h1-font-weight: var(--font-weight-medium);
    --typography-typography-components-h2-letter-spacing: var(--font-letter-spacing-tight);
    --typography-typography-components-h2-font-size: var(--font-size-text-3xl);
    --typography-typography-components-h2-line-height: var(--font-line-height-leading-9);
    --typography-typography-components-h2-font-weight: var(--font-weight-medium);
    --typography-typography-components-h3-letter-spacing: var(--font-letter-spacing-tight);
    --typography-typography-components-h3-font-size: var(--font-size-text-2xl);
    --typography-typography-components-h3-line-height: var(--font-line-height-leading-8);
    --typography-typography-components-h3-font-weight: var(--font-weight-medium);
    --typography-typography-components-h4-letter-spacing: var(--font-letter-spacing-tight);
    --typography-typography-components-h4-font-size: var(--font-size-text-xl);
    --typography-typography-components-h4-line-height: var(--font-line-height-leading-7);
    --typography-typography-components-h4-font-weight: var(--font-weight-medium);
    --typography-typography-components-inline-code-letter-spacing: var(--font-letter-spacing-normal);
    --typography-typography-components-inline-code-font-size: var(--font-size-text-sm);
    --typography-typography-components-inline-code-line-height: var(--font-line-height-leading-5);
    --typography-typography-components-inline-code-font-weight: var(--font-weight-semibold);
    --typography-typography-components-large-letter-spacing: var(--font-letter-spacing-normal);
    --typography-typography-components-large-font-size: var(--font-size-text-lg);
    --typography-typography-components-large-line-height: var(--font-line-height-leading-7);
    --typography-typography-components-large-font-weight: var(--font-weight-semibold);
    --typography-typography-components-lead-letter-spacing: var(--font-letter-spacing-normal);
    --typography-typography-components-lead-font-size: var(--font-size-text-xl);
    --typography-typography-components-lead-line-height: var(--font-line-height-leading-7);
    --typography-typography-components-lead-font-weight: var(--font-weight-normal);
    --typography-typography-components-list-letter-spacing: var(--font-letter-spacing-normal);
    --typography-typography-components-list-font-size: var(--font-size-text-base);
    --typography-typography-components-list-line-height: var(--font-line-height-leading-7);
    --typography-typography-components-list-font-weight: var(--font-weight-normal);
    --typography-typography-components-p-letter-spacing: var(--font-letter-spacing-normal);
    --typography-typography-components-p-font-size: var(--font-size-text-base);
    --typography-typography-components-p-line-height: var(--font-line-height-leading-7);
    --typography-typography-components-p-font-weight: var(--font-weight-normal);
    --typography-typography-components-small-font-size: var(--font-size-text-sm);
    --typography-typography-components-small-line-height: var(--font-size-text-sm);
    --typography-typography-components-small-letter-spacing: var(--font-line-height-leading-4);
    --typography-typography-components-small-font-weight: var(--font-weight-medium);
    --typography-typography-components-table-letter-spacing: var(--font-letter-spacing-normal);
    --typography-typography-components-table-font-size: var(--font-size-text-base);
    --typography-typography-components-table-font-weight: var(--font-weight-normal);
    --typography-typography-components-table-font-weight-bold: var(--font-weight-bold);
    /* string */
    --typography-font-family-font-mono: Menlo;
    --typography-font-family-font-sans: Inter;
    --typography-font-family-font-serif: Georgia;
    --typography-typography-components-blockquote-font-family: var(--typography-font-family-font-sans);
    --typography-typography-components-blockquote-font-style: var(--font-style-italic);
    --typography-typography-components-h1-font-family: var(--typography-font-family-font-sans);
    --typography-typography-components-h2-font-family: var(--typography-font-family-font-sans);
    --typography-typography-components-h3-font-family: var(--typography-font-family-font-sans);
    --typography-typography-components-h4-font-family: var(--typography-font-family-font-sans);
    --typography-typography-components-inline-code-font-family: var(--typography-font-family-font-mono);
    --typography-typography-components-large-font-family: var(--typography-font-family-font-sans);
    --typography-typography-components-lead-font-family: var(--typography-font-family-font-sans);
    --typography-typography-components-list-font-family: var(--typography-font-family-font-sans);
    --typography-typography-components-p-font-family: var(--typography-font-family-font-sans);
    --typography-typography-components-small-font-family: var(--typography-font-family-font-sans);
    --typography-typography-components-table-font-family: var(--typography-font-family-font-sans);
   
    /* new figma light variables */
    --alpha-10: #ffffffe5;
    --alpha-20: #ffffffcc;
    --alpha-30: #ffffffb2;
    --alpha-40: #ffffff99;
    --alpha-50: #ffffff80;
    --alpha-60: #ffffff66;
    --alpha-70: #ffffff4d;
    --alpha-80: #ffffff33;
    --alpha-90: #ffffff1a;
    --base-accent: var(--colors-accent-light);
    --base-accent-foreground: var(--colors-accent-foreground-light);
    --base-background: var(--colors-background-light);
    --base-border: var(--colors-border-light);
    --base-button-accent: var(--colors-button-accent-light);
    --base-card: var(--colors-card-light);
    --base-card-foreground: var(--colors-card-foreground-light);
    --base-chart-1: var(--colors-chart-1-light);
    --base-chart-2: var(--colors-chart-2-light);
    --base-chart-3: var(--colors-chart-3-light);
    --base-chart-4: var(--colors-chart-4-light);
    --base-chart-5: var(--colors-chart-5-light);
    --base-destructive: var(--colors-destructive-light);
    --base-destructive-foreground: var(--colors-destructive-foreground-light);
    --base-foreground: var(--colors-foreground-light);
    --base-input: var(--colors-input-light);
    --base-muted: var(--colors-muted-light);
    --base-muted-foreground: var(--colors-muted-foreground-light);
    --base-opened-background: var(--colors-open-bg-light);
    --base-opened-foreground: var(--colors-open-foreground-light);
    --base-popover: var(--colors-popover-light);
    --base-popover-foreground: var(--colors-popover-foreground-light);
    --base-positive: var(--colors-positive-light);
    --base-primary: var(--colors-primary-light);
    --base-primary-foreground: var(--colors-primary-foreground-light);
    --base-reply-background: var(--colors-reply-bg-light);
    --base-reply-foreground: var(--colors-reply-foreground-light);
    --base-ring: var(--colors-ring-light);
    --base-ring-offset: 0 0% 100;
    --base-secondary: var(--colors-secondary-light);
    --base-secondary-foreground: var(--colors-secondary-foreground-light);
    --base-sent-background: var(--colors-sent-bg-light);
    --base-sent-foreground: var(--colors-sent-foreground-light);
    --base-sidebar-accent: var(--colors-sidebar-accent-light);
    --base-sidebar-accent-foreground: var(--colors-sidebar-accent-foreground-light);
    --base-sidebar-background: var(--colors-sidebar-background-light);
    --base-sidebar-background-active: 214 100% 97%;
    --base-sidebar-border: var(--colors-sidebar-border-light);
    --base-sidebar-foreground: var(--colors-sidebar-foreground-light);
    --base-sidebar-muted-foreground: var(--colors-sidebar-muted-foreground-light);
    --base-sidebar-primary: var(--colors-sidebar-primary-light);
    --base-sidebar-primary-foreground: var(--colors-sidebar-primary-foreground-light);
    --base-sidebar-ring: var(--colors-sidebar-ring-light);
    --base-sidebar-sub-foreground: var(--colors-sidebar-sub-foreground-light);
    --base-sub-foreground: var(--colors-sub-foreground-light);
  }

  .dark {
    --border: 217 33% 17%;
    --input: 215 27.9% 16.9%;
    --ring: 263.4 70% 50.4%;

    /* new figma dark variables */
    --alpha-10: #09090be5;
    --alpha-20: #09090bcc;
    --alpha-30: #09090bb2;
    --alpha-40: #09090b99;
    --alpha-50: #09090b80;
    --alpha-60: #09090b66;
    --alpha-70: #09090b4d;
    --alpha-80: #09090b33;
    --alpha-90: #09090b1a;
    --base-accent: var(--colors-accent-dark);
    --base-accent-foreground: var(--colors-accent-foreground-dark);
    --base-background: var(--colors-background-dark);
    --base-border: var(--colors-border-dark);
    --base-button-accent: var(--colors-button-accent-dark);
    --base-card: var(--colors-card-dark);
    --base-card-foreground: var(--colors-card-foreground-dark);
    --base-chart-1: var(--colors-chart-1-dark);
    --base-chart-2: var(--colors-chart-2-dark);
    --base-chart-3: var(--colors-chart-3-dark);
    --base-chart-4: var(--colors-chart-4-dark);
    --base-chart-5: var(--colors-chart-5-dark);
    --base-destructive: var(--colors-destructive-dark);
    --base-destructive-foreground: var(--colors-destructive-foreground-dark);
    --base-foreground: var(--colors-foreground-dark);
    --base-input: var(--colors-input-dark);
    --base-muted: var(--colors-muted-dark);
    --base-muted-foreground: var(--colors-muted-foreground-dark);
    --base-opened-background: var(--colors-open-bg-dark);
    --base-opened-foreground: var(--colors-open-foreground-dark);
    --base-popover: var(--colors-popover-dark);
    --base-popover-foreground: var(--colors-popover-foreground-dark);
    --base-positive: var(--colors-positive-dark);
    --base-primary: var(--colors-primary-dark);
    --base-primary-foreground: var(--colors-primary-foreground-dark);
    --base-reply-background: var(--colors-reply-bg-dark);
    --base-reply-foreground: var(--colors-reply-foreground-dark);
    --base-ring: var(--colors-ring-dark);
    --base-ring-offset: 0 0% 100;
    --base-secondary: var(--colors-secondary-dark);
    --base-secondary-foreground: var(--colors-secondary-foreground-dark);
    --base-sent-background: var(--colors-sent-bg-dark);
    --base-sent-foreground: var(--colors-sent-foreground-dark);
    --base-sidebar-accent: var(--colors-sidebar-accent-dark);
    --base-sidebar-accent-foreground: var(--colors-sidebar-accent-foreground-dark);
    --base-sidebar-background: var(--colors-sidebar-background-dark);
    --base-sidebar-background-active: 226 57% 21%;
    --base-sidebar-border: var(--colors-sidebar-border-dark);
    --base-sidebar-foreground: var(--colors-sidebar-foreground-dark);
    --base-sidebar-muted-foreground: var(--colors-sidebar-muted-foreground-dark);
    --base-sidebar-primary: var(--colors-sidebar-primary-dark);
    --base-sidebar-primary-foreground: var(--colors-sidebar-primary-foreground-dark);
    --base-sidebar-ring: var(--colors-sidebar-ring-dark);
    --base-sidebar-sub-foreground: var(--colors-sidebar-sub-foreground-dark);
    --base-sub-foreground: var(--colors-sub-foreground-dark);
  }

  /* Custom scrollbar styles */
  .scrollbar-visible {
    scrollbar-width: thin !important;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground antialiased;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }

  .custom-textarea:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }
 
  .editor-textUnderline {
    text-decoration: underline;
  }
  
  .editor-textItalic {
    font-style: italic;
  }
  
  .editor-textBold {
    font-weight: bold;
  }
  
  .editor-list-ol {
    list-style-type: decimal;
  }
  
  .editor-list-ul {
    list-style-type: disc;
  }
  
  .editor-listItem {
    margin-left: 20px;
  }
  
  .editor-nested-listitem {
    margin-left: 20px;
  }
  
  .editor-link {
    color: #0000ff;
    text-decoration: underline;
  }
}
